import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";




//Tech Stack
// import FrontEnd from "./pages/find-developer/frontend";
// import Angular from "./pages/find-developer/frontend/angular";
// import Javascript from "./pages/find-developer/frontend/javascript";
// import ReactPage from "./pages/find-developer/frontend/react";
// import BackendPage from "./pages/find-developer/backend";
// import JavaPage from "./pages/find-developer/backend/java";
// import DotNetPage from "./pages/find-developer/backend/net";
// import PhpPage from "./pages/find-developer/backend/php";
// import PythonPage from "./pages/find-developer/backend/python";
// import MobilePage from "./pages/find-developer/mobile";
// import AndroidPage from "./pages/find-developer/mobile/android";
// import IOSPage from "./pages/find-developer/mobile/ios";



// const HomePage = lazy(() => import("./pages/home"));

//Services
// const CustomDevelopmentPage = lazy(() => import("./pages/services/custom-development"));
// const DevopsPage = lazy(() => import("./pages/services/devops"));
// const CloudDevelopmentPage = lazy(() => import("./pages/services/cloud-development"));
// const QAPage = lazy(() => import("./pages/services/qa"));
// const BusinessAnalysisPage = lazy(() => import("./pages/services/business-analysis"));
// const ItSecurityPage = lazy(() => import("./pages/services/it-security"));
// const ProjectManagmentPage = lazy(() => import("./pages/services/project-managment"));
// const TeamAugmentationPage = lazy(() => import("./pages/services/team-augmentation"));
// const ResourceAugmentationPage = lazy(() => import("./pages/services/resource-augmentation"));
const SalesForcePage = lazy(() => import("./pages/services/salesforce"));
// const UiUxPage = lazy(() => import("./pages/services/ui-ux"));
// const ApplicationMaintenancePage = lazy(() => import("./pages/services/application-maintenance"));
// const DataSciencePage = lazy(() => import("./pages/services/data-science"));

//Company
const AboutUsPage = lazy(() => import("./pages/company/about"));
const CareersPage = lazy(() => import("./pages/company/careers"));
const PrivacyPolicyPage = lazy(() => import("./pages/company/privacy-policy"));



// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <Suspense fallback={<LoadingMessage />}>
        <Switch>
            <Route exact path="/" component={SalesForcePage} />

            {/*<Route exact path="/find-developers/front-end" component={FrontEnd}/>*/}
            {/*<Route exact path="/find-developers/angular" component={Angular}/>*/}
            {/*<Route exact path="/find-developers/js" component={Javascript}/>*/}
            {/*<Route exact path="/find-developers/react" component={ReactPage}/>*/}
            {/*<Route exact path="/find-developers/back-end" component={BackendPage}/>*/}
            {/*<Route exact path="/find-developers/java" component={JavaPage}/>*/}
            {/*<Route exact path="/find-developers/dotnet" component={DotNetPage}/>*/}
            {/*<Route exact path="/find-developers/php" component={PhpPage}/>*/}
            {/*<Route exact path="/find-developers/python" component={PythonPage}/>*/}
            {/*<Route exact path="/find-developers/mobile" component={MobilePage}/>*/}
            {/*<Route exact path="/find-developers/android" component={AndroidPage}/>*/}
            {/*<Route exact path="/find-developers/ios" component={IOSPage}/>*/}

            {/*<Route exact path="/services/custom-sw-dev" component={CustomDevelopmentPage}/>*/}
            {/*<Route exact path="/services/devops" component={DevopsPage}/>*/}
            {/*<Route exact path="/services/cloud-development" component={CloudDevelopmentPage}/>*/}
            {/*<Route exact path="/services/qa" component={QAPage}/>*/}
            {/*<Route exact path="/services/business-analysis" component={BusinessAnalysisPage}/>*/}
            {/*<Route exact path="/services/it-security" component={ItSecurityPage}/>*/}
            {/*<Route exact path="/services/project-management" component={ProjectManagmentPage}/>*/}
            {/* <Route exact path="/services/team-augmentation" component={TeamAugmentationPage}/> */}
            {/* <Route exact path="/services/resource-augmentation" component={ResourceAugmentationPage}/> */}
            {/* <Route exact path="/services/salesforce" component={SalesForcePage}/> */}
            {/*<Route exact path="/services/ui-ux" component={UiUxPage}/>*/}
            {/* <Route exact path="/services/app-maintainance-support" component={ApplicationMaintenancePage}/> */}
            {/* <Route exact path="/services/data-science" component={DataSciencePage}/> */}

            <Route exact path="/company/about-us" component={AboutUsPage} />
            {/*<Route exact path="/company/corporate-life" component={CorporateLifePage}/>*/}
            {/*<Route exact path="/company/testimonials" component={TestimonialsPage}/>*/}
            <Route exact path="/company/careers" component={CareersPage} />

            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />

            <Route component={SalesForcePage} />


        </Switch>
    </Suspense>
);

const LoadingMessage = () => {
    return (
        <div>Loading...</div>
    );
};
